import React, { FunctionComponent, useEffect, useState } from 'react';
import { Order } from '../../../types/Order';
import { t } from '../../../intl/Translator';
import { Button } from '../button';
import OrderService from '../../../services/OrderService';
import styles from './receipt-image.module.scss';

interface Props {
    order: Order,
}

export const ReceiptImage: FunctionComponent<Props> = props => {
    const [base64, setBase64] = useState('');

    useEffect(() => {
        OrderService.getReceipt(props.order)
            .then(setBase64);
    }, [props.order]);

    const downloadImage = () => {
        const link = document.createElement('a');
        link.download = `receipt-${props.order?.id}.png`;
        link.href = base64;
        link.click();
    };

    if (!base64)
        return null;

    return <div className={styles.receiptImage}>
        <img src={base64} alt='Receipt' />
        <Button label={t.translate('downloadReceipt')} onClick={downloadImage} />
    </div>;
};
