import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { LoginRequest, LoginResponse, Partner } from '../types/User';

const baseUrl = process.env.REACT_APP_USER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class UserService {
    public static createPartner = (): Promise<Partner> =>
        Api.post(generateUrl('partner'));

    public static login = (obj: LoginRequest): Promise<LoginResponse> =>
        Api.post<LoginResponse>(generateUrl('partner', 'login'), obj);
}
