import React, { FunctionComponent, useEffect } from 'react';
import styles from './charge.module.scss';
import QRCode from 'react-qr-code';
import { t } from '../../../intl/Translator';
import { useSelector } from 'react-redux';
import { closeCharge, getCharge, getIsChargeOpen, getOrderFromCharge } from '../../../store/orderSlice';
import { Dialog } from '../../utils/dialog';
import { useAppDispatch } from '../../../hooks';
import { Order, OrderStatus } from '../../../types/Order';
import { WithData } from '../../../types';
import { Charge as ChargeModel } from '../../../types/Charge';
import { Loading } from '../../utils/loading';
import { SuccessCheckMark } from '../../utils/success-check-mark';
import { Label } from '../../utils/label';
import { formatPriceAmount } from '../../../utils';
import { ReceiptImage } from "../../utils/receipt-image";
import { getFpaidHost } from "../../../store";

export const Charge: React.FC = () => {
    const dispatch = useAppDispatch();
    const charge = useSelector(getCharge);
    const order = useSelector(getOrderFromCharge);
    const isCharging = useSelector(getIsChargeOpen);

    const renderDetail = () => {
        if (!charge)
            return <div></div>;

        if (!!order) {
            if (order.status === OrderStatus.created)
                return <PendingOrder data={order} />;

            return <PaidOrder data={order} />;
        }

        return <PendingCharge data={charge} />;
    };

    return (
        <Dialog
            isOpen={isCharging}
            title={t.translate('charge')}
            onClose={() => dispatch(closeCharge())}>
            {renderDetail()}
        </Dialog>
    );
};

const PaidOrder: FunctionComponent<WithData<Order>> = ({ data }) => {
    const statusStyle = {
        [OrderStatus.paid]: styles.paid,
        [OrderStatus.pending]: styles.pending,
        [OrderStatus.declined]: styles.rejected,
        [OrderStatus.created]: '',
    }[data.status];

    return (
        <div className={styles.charge}>
            <SuccessCheckMark />
            <div className={styles.content}>
                <div className={styles.infos}>
                    <BasicInfo data={data} />
                    <Label
                        text={t.translate('status')}
                        content={t.translate(data.status)}
                        className={statusStyle}
                    />
                </div>
            </div>
            <div className={styles.receipt}>
                <ReceiptImage order={data} />
            </div>
        </div>
    );
};

const PendingOrder: FunctionComponent<WithData<Order>> = ({ data }) => (
    <div className={styles.charge}>
        <Loading />
        <div className={styles.content}>
            <div className={styles.infos}>
                <BasicInfo data={data} />
            </div>
        </div>
    </div>
);

const timeToExpire = 600;
const PendingCharge: FunctionComponent<WithData<ChargeModel>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const [expiresInSeconds, setExpiresInSeconds] = React.useState<number>(timeToExpire);
    const qrCodeValue = `${getFpaidHost()}/?chargeId=${data.id}`;

    useEffect(() => {
        const timer = setInterval(() => {
            const newVal = expiresInSeconds - 1;
            if (!newVal) {
                dispatch(closeCharge());
                return;
            }

            setExpiresInSeconds(newVal);
        }, 1000);

        return () => clearInterval(timer);
    }, [expiresInSeconds, dispatch]);

    const minutesToExpire = Math.floor(expiresInSeconds / 60);
    const secondsToExpire = expiresInSeconds - minutesToExpire * 60;

    return (
        <div className={styles.charge}>
            <div className={styles.qrCode}>
                <QRCode value={qrCodeValue} size={200} />
            </div>
            <div className={styles.content}>
                <div className={styles.infos}>
                    <BasicInfo data={data} />
                </div>
                <div className={styles.timeout}>
                    <div className={styles.bar}>
                        <div className={styles.progress}
                             style={{ width: `${100 * expiresInSeconds / timeToExpire}%` }}></div>
                    </div>
                    <span>{minutesToExpire}:{('0' + secondsToExpire).slice(-2)} {t.translate('timeout')}</span>
                </div>
            </div>
        </div>
    );
};

const BasicInfo: FunctionComponent<WithData<ChargeModel | Order>> = ({ data }) => (
    <>
        <Label
            text={t.translate('walletAddress')}
            content={data.address}
        />
        <Label
            text={t.translate('amount')}
            content={formatPriceAmount(data.amount)}
        />
    </>
);
