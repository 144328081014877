import React from 'react';
import styles from './button.module.scss';

export enum ButtonAppearance {
    Primary = 'primary',
    Outline = 'outline',
}

interface Props {
    label: string;
    appearance?: ButtonAppearance;
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
}

export const Button: React.FunctionComponent<Props> = (props) => {
    const defaultProps: Props = {
        label: '',
        appearance: ButtonAppearance.Primary,
        disabled: false,
        onClick: () => {},
        loading: false,
    };
    props = { ...defaultProps, ...props };

    const className = `
        ${styles.button}
        ${props.appearance}
        ${props.disabled ?? props.loading ? styles.disabled : ''}
    `;

    const onClick = () => {
        if (props.disabled || props.loading) return;
        props.onClick();
    };

    return (
        <div
            className={className}
            onClick={onClick}
        >
            <span>{props.label}</span>
        </div>
    );
};
