import React from 'react';
import { Toolbar } from './components/toolbar';
import styles from './app.module.scss';
import { Statement } from './components/statement';
import { Home } from './components/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EditAddress } from './components/pop-ups/edit-address';
import { Alerts } from './components/alerts';

function App() {
    return (
        <div className={styles.app}>
            <BrowserRouter>
                <Toolbar />
                <div className={styles.content}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/statement' element={<Statement />} />
                    </Routes>
                </div>
                <EditAddress></EditAddress>
            </BrowserRouter>
            <Alerts />
        </div>
    );
}

export default App;
