import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { Charge, ChargeRequest } from '../types/Charge';
import { Order } from '../types/Order';

const baseUrl = process.env.REACT_APP_ORDER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class OrderService {
    public static charge = (obj: ChargeRequest): Promise<Charge> =>
        Api.post<Charge>(generateUrl('charge'), obj);

    public static getReceipt = (order: Order): Promise<string> =>
        Api.get<{base64: string}>(generateUrl('receipt', 'image', order.id))
            .then(x => x.base64);
}
