import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { Order } from '../types/Order';

interface StatementState {
    showTotal: boolean;
    selectedOrderId: string | null;
}

const initialState: StatementState = {
    showTotal: false,
    selectedOrderId: null,
};

const statementSlice = createSlice({
    name: 'statement',
    initialState,
    reducers: {
        updateShowTotal: (state, action: PayloadAction<boolean>) => {
            state.showTotal = action.payload;
        },
        updateSelectedOrderId: (state, action: PayloadAction<string | null>) => {
            state.selectedOrderId = action.payload;
        },
    },
});

const { updateShowTotal } = statementSlice.actions;
export const { updateSelectedOrderId } = statementSlice.actions;

export const toggleShowTotal = (): AppThunk => (dispatch, getState) => {
    const showTotal = getShowTotal(getState());
    dispatch(updateShowTotal(!showTotal));
};


export const getShowTotal = (state: RootState): boolean => state.statementSlice.showTotal;
export const getSelectedOrder = (state: RootState): Order | undefined => state.orderSlice.lstOrders.find(x => x.id === state.statementSlice.selectedOrderId)


export default statementSlice.reducer;