import React from 'react';
import { Field } from '../utils/field';
import { Button, ButtonAppearance } from '../utils/button';
import styles from './home.module.scss';
import EditIcon from '../../assets/images/icons/edit.png';
import { t } from '../../intl/Translator';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { getAddress, setIsEditingAddress } from '../../store/appSlice';
import { newCharge } from '../../store/orderSlice';
import { Charge } from '../pop-ups/charge';
import { ChargeRequest } from '../../types/Charge';

interface HomeProps {}

export const Home: React.FC<HomeProps> = (props) => {
    const dispatch = useAppDispatch();
    const address = useSelector(getAddress);

    const [rawAmount, setRawAmount] = React.useState<string | null>(null);

    const amount = React.useMemo(() => {
        if (!rawAmount)
            return null;

        return Math.round(Number(rawAmount.replace(/\D/g, ''))) / 100;
    }, [rawAmount]);

    const onGeneratePayment = () => {
        if (!amount)
            return;

        const req: ChargeRequest = { address, amount: amount };
        dispatch(newCharge(req));
    };

    return (
        <div className={styles.home}>
            <div className={styles.address}>
                <span>{t.translate('walletAddress')}</span>
                <div className={styles.wallet}>
                    <span>{address}</span>
                    <img src={EditIcon}
                         alt={t.translate('editAddress')}
                         onClick={() => dispatch(setIsEditingAddress(true))} />
                </div>
            </div>
            <Field
                label={t.translate('amount')}
                placeholder={'420,20'}
                value={rawAmount}
                onChange={setRawAmount}
                className={styles.field}
                type={'currency'}
                prefix={'R$'}
            />
            <div className={styles.button}>
                <Button
                    label={t.translate('generate')}
                    appearance={ButtonAppearance.Primary}
                    onClick={onGeneratePayment}
                    disabled={!amount}
                />
            </div>
            <Charge />
        </div>
    );
};
