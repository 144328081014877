import React from 'react';
import styles from './toolbar.module.scss';
import { t } from '../../intl/Translator';
import { useLocation, useNavigate } from 'react-router-dom';

interface Menu {
    path: string;
    labelKey: string;
}

const menus: Menu[] = [
    { path: '/', labelKey: 'home' },
    { path: '/statement', labelKey: 'statement' },
];

export const Toolbar: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const renderMenuItem = (menu: Menu): JSX.Element => {
        const label = t.translate(menu.labelKey);
        const selectedStyle = location.pathname === menu.path ? styles.selected : '';
        const className = `${styles.page} ${selectedStyle}`;

        return (
            <span
                key={menu.labelKey}
                className={className}
                onClick={() => navigate(menu.path)}
            >
                {label}
            </span>
        );

    };

    return <div className={styles.toolbar}>
        {menus.map(renderMenuItem)}
    </div>;
};
