import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';

interface AppState {
    address: string;
    isEditingAddress: boolean;
}

const initialState: AppState = {
    isEditingAddress: true,
    address: '',
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
            state.isEditingAddress = false;
        },
        updateIsEditingAddress: (state, action: PayloadAction<boolean>) => {
            state.isEditingAddress = action.payload;
        },
    },
});


const { updateAddress, updateIsEditingAddress } = appSlice.actions;

export const getAddress = (state: RootState): string => state.appSlice.address;
export const isEditingAddress = (state: RootState): boolean => state.appSlice.isEditingAddress;

export const setIsEditingAddress = (isEditing: boolean): AppThunk => (dispatch) => {
    dispatch(updateIsEditingAddress(isEditing));
};

export const setAddress = (address: string): AppThunk => async dispatch => {
    localStorage.setItem('address', address);
    dispatch(updateAddress(address));
};

export const setUpAppState = (): AppThunk => async dispatch => {
    const address = localStorage.getItem('address');
    if (!address) return;

    dispatch(setAddress(address));
};
export default appSlice.reducer;