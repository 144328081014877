import { LoginRequest, Partner } from '../types/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import UserService from '../services/UserService';
import { Api } from '../services/Api';
import { userFromToken } from '../utils';
import { WebSocket } from '../services/WebSocket';
import { authenticator } from 'otplib';

export const TOKEN_KEY = 'token';
const PARTNER_KEY = 'partner';

interface AuthState {
    partner: Partner | null,
    tokenExpiresAt: number | null;
    error: string | null;
}

const initialState: AuthState = {
    partner: null,
    tokenExpiresAt: null,
    error: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updatePartner: (state, action: PayloadAction<Partner | null>) => {
            state.partner = action.payload;
        },
        updateError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        updateTokenExpiration: (state, action: PayloadAction<number | null>) => {
            state.tokenExpiresAt = action.payload;
        },
    },
});

export const { updateError } = authSlice.actions;
const { updatePartner, updateTokenExpiration } = authSlice.actions;

export const setUpPartner = (): AppThunk => async dispatch => {
    const newPartner = (): Promise<Partner> => UserService.createPartner()
        .then(x => {
            localStorage.setItem(PARTNER_KEY, JSON.stringify(x));
            return x;
        });

    const value = localStorage.getItem(PARTNER_KEY);
    const partner = !!value ? JSON.parse(value) as Partner : await newPartner();
    dispatch(updatePartner(partner));
    dispatch(login(partner));
};

let timeout: NodeJS.Timeout;
export const updateToken = (token: string): AppThunk => async dispatch => {
    localStorage.setItem(TOKEN_KEY, token);
    Api.setToken(token);
    WebSocket.updateToken(token);

    const user = userFromToken(token)!;

    const expiration = (user as any).exp;
    if (expiration <= Date.now()) {
        dispatch(setUpPartner());
        return;
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(setUpPartner()), expiration - Date.now());

    dispatch(updateTokenExpiration((user as any).exp));
    dispatch(updatePartner(user));

    await WebSocket.openWebSocketConnection();
};

export const login = (partner: Partner): AppThunk => dispatch => {
    const twoFactorAuthCode = authenticator.generate(partner.totpSecret);
    const req: LoginRequest = {
        id: partner.id,
        twoFactorAuthCode,
    };

    return UserService.login(req)
        .then(({ token }) => {
            dispatch(updateToken(token));

        })
        .catch(({ message }) => dispatch(updateError(message)));
};

export const forgetPartner = (): AppThunk => dispatch => {
    Api.setToken('');
    WebSocket.updateToken('');

    dispatch(updateTokenExpiration(null));
    dispatch(updatePartner(null));
};

export default authSlice.reducer;