export type DictionaryTerm = {
    pt: string;
    en: string;
    ch: string;
}


export const translationDictionary = new Map<string, DictionaryTerm>()
    .set('home', { en: 'Home', pt: 'Início', ch: '首页' })
    .set('statement', { en: 'Statement', pt: 'Extrato', ch: '声明' })
    .set('noTransactions', {en: 'No transactions', pt: 'Nenhuma transação', ch: '没有交易'})
    .set('wallet', { en: 'Wallet', pt: 'Carteira', ch: '钱包' })
    .set('walletAddress', { en: 'USDT Address', pt: 'Endereço USDT', ch: 'USDT地址' })
    .set('editAddress', { en: 'Edit edit-address', pt: 'Editar endereço', ch: '编辑地址' })
    .set('addressNetworks', {
        en: 'Address must be valid in Ethereum or TRON Network.',
        pt: 'Endereço deve ser válido na rede Ethereum ou TRON',
        ch: '地址必须有效在以太坊或波场网络。',
    })
    .set('enter', { en: 'Save', pt: 'Salvar', ch: '保存' })
    .set('amount', { en: 'Amount', pt: 'Valor', ch: '金额' })
    .set('generate', { en: 'Generate', pt: 'Gerar', ch: '生成' })
    .set('charge', { en: 'Charge', pt: 'Cobrança', ch: '费用' })
    .set('timeout', { en: 'to expire', pt: 'para expirar', ch: '到期' })
    .set('status', { en: 'Status', pt: 'Status', ch: '状态' })
    .set('created', { en: 'Created', pt: 'Criado', ch: '已创建' })
    .set('paid', { en: 'Paid', pt: 'Pago', ch: '已付' })
    .set('pending', { en: 'Pending', pt: 'Pendente', ch: '挂起' })
    .set('declined', { en: 'Declined', pt: 'Recusado', ch: '拒绝' })
    .set('paymentReceipt', { en: 'Payment receipt', pt: 'Recibo de pagamento', ch: '付款收据' })
    .set('downloadReceipt', { en: 'Download Receipt', pt: 'Baixar recibo', ch: '下载收据' })
    .set('total', { en: 'Total', pt: 'Total', ch: '总' })
    .set('showTotal', { en: 'Show total', pt: 'Mostrar total', ch: '显示总计' })
    .set('hideTotal', { en: 'Hide total', pt: 'Ocultar total', ch: '隐藏总计' })
    .set('today', { en: 'Today', pt: 'Hoje', ch: '今天' })
    .set('yesterday', { en: 'Yesterday', pt: 'Ontem', ch: '昨天' })
    .set('transaction', { en: 'Transaction', pt: 'Transação', ch: '交易' })
    .set('date', { en: 'Date', pt: 'Data', ch: '日期' })
    .set('bank', { en: 'Bank', pt: 'Banco', ch: '银行' })
    .set('paymentMethod', { en: 'Payment method', pt: 'Método de pagamento', ch: '付款方式' })
    .set('externalId', { en: 'External ID', pt: 'ID externo', ch: '外部ID' })
    .set('paymentDate', { en: 'Payment date', pt: 'Data de pagamento', ch: '付款日期' })
    .set('reason', { en: 'Reason', pt: 'Motivo', ch: '原因' });
