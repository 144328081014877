import React, { FunctionComponent } from 'react';
import styles from './statement.module.scss';
import VisibilityIcon from '../../assets/images/icons/visibility.svg';
import VisibilityOffIcon from '../../assets/images/icons/visibility_off.svg';
import { OrderDetailInfo } from '../pop-ups/order-detail';
import { t } from '../../intl/Translator';
import { useSelector } from 'react-redux';
import { getSelectedOrder, getShowTotal, toggleShowTotal, updateSelectedOrderId } from '../../store/statementSlice';
import { useAppDispatch } from '../../hooks';
import { getOrdersMapByDate, getOrdersTotal } from '../../store/orderSlice';
import { Order, OrderStatus } from '../../types/Order';
import { formatDateWithName, formatPriceAmount } from '../../utils';

const StatementItem: FunctionComponent<Order> = (order) => {
    const dispatch = useAppDispatch();

    const statusStyle = {
        [OrderStatus.created]: '',
        [OrderStatus.declined]: styles.rejected,
        [OrderStatus.pending]: styles.pending,
        [OrderStatus.paid]: '',
    }[order.status];

    const time = order.createdAt.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });

    return <div key={order.id} className={`${styles.transaction} ${statusStyle}`}
                onClick={() => dispatch(updateSelectedOrderId(order.id))}>
        <div className={styles.transactionInfo}>
            <span>{formatPriceAmount(order.amount)}</span>
            <span>{order.address}</span>
        </div>
        <div className={styles.time}>
            <span>{time}</span>
        </div>
    </div>;
};

export const Statement: React.FC = () => {
    const dispatch = useAppDispatch();

    const showTotal = useSelector(getShowTotal);
    const ordersTotal = useSelector(getOrdersTotal);
    const ordersMapByDate = useSelector(getOrdersMapByDate);
    const selectedOrder = useSelector(getSelectedOrder);

    const lst = [...ordersMapByDate];
    return (
        <div className={styles.statement}>
            <div className={styles.content}>
                <div className={styles.total}>
                    <span>{t.translate('total')}</span>
                    <div className={styles.totalValue}>
                        {!showTotal &&
                            <>
                                <span>R$ {'•'.repeat(6)}</span>
                                <img src={VisibilityIcon}
                                     alt={t.translate('showTotal')}
                                     onClick={() => dispatch(toggleShowTotal())} />
                            </>
                        }
                        {showTotal &&
                            <>
                                <span>{formatPriceAmount(ordersTotal)}</span>
                                <img src={VisibilityOffIcon}
                                     alt={t.translate('hideTotal')}
                                     onClick={() => dispatch(toggleShowTotal())} />
                            </>
                        }
                    </div>
                </div>
                {lst.map(([dt, filteredOrders]) => {
                    const date = new Date(dt);
                    return <div className={styles.transactionDate}>
                        <span>{formatDateWithName(date)}</span>
                        <div className={styles.transactions}>
                            {filteredOrders.map(StatementItem)}
                        </div>
                    </div>;
                })}

                {!lst.length && (
                    <div className={styles.noTransactions}>{t.translate('noTransactions')}</div>
                )}
            </div>
            <OrderDetailInfo data={selectedOrder}></OrderDetailInfo>
        </div>
    );
};
