import React, { FunctionComponent } from 'react';
import styles from './order-detail.module.scss';
import {
    CreatedOrder,
    DeclinedOrder,
    Order,
    OrderStatus,
    PaidManuallyApprovedOrder,
    PaidOrder,
    PaidPixOrder,
    PaymentType,
    PendingOrder,
} from '../../../types/Order';
import { Dialog } from '../../utils/dialog';
import { Label } from '../../utils/label';
import { WithData } from '../../../types';
import { getOrderStatusStyle, getPaymentMethodLabel } from '../../../utils/orderHelpers';
import { ReceiptImage } from '../../utils/receipt-image';
import { useAppDispatch } from '../../../hooks';
import { updateSelectedOrderId } from '../../../store/statementSlice';
import { t } from '../../../intl/Translator';
import { formatDateWithTime, formatPriceAmount, getOrderStatusKey } from '../../../utils';
import { Button } from '../../utils/button';

export const OrderDetailInfo: FunctionComponent<WithData<Order | undefined>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const getPaidComponent = (): JSX.Element => {
        const paidOrder = data as PaidOrder;
        const dicPaidComponents: Record<PaymentType, () => JSX.Element> = {
            [PaymentType.ted]: () => <OrderPaidWithTed data={data as PaidManuallyApprovedOrder} />,
            [PaymentType.pix]: () => <OrderPaidWithPix data={data as PaidPixOrder} />,
        };

        return dicPaidComponents[paidOrder.payment.type]();
    };

    const dicComponentsByState: Record<OrderStatus, () => JSX.Element> = {
        [OrderStatus.created]: () => <OrderCreated data={data as CreatedOrder} />,
        [OrderStatus.pending]: () => <OrderPending data={data as PendingOrder} />,
        [OrderStatus.declined]: () => <OrderDeclined data={data as DeclinedOrder} />,
        [OrderStatus.paid]: getPaidComponent,
    };

    return <Dialog
        isOpen={!!data}
        title={t.translate('transaction')}
        onClose={() => dispatch(updateSelectedOrderId(null))}
        children={(() => {
            if (!data)
                return <div></div>;

            return dicComponentsByState[data.status]();
        })()}
    />;
};

const OrderCreated: FunctionComponent<WithData<CreatedOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data} />
        </div>
    </div>
);

const OrderDeclined: FunctionComponent<WithData<DeclinedOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data} />
            <Label text={t.translate('reason')} content={data.payment.motive} />
        </div>
    </div>
);

const OrderPending: FunctionComponent<WithData<PendingOrder>> = ({ data }) => {
    return (
        <>
            <div className={styles.content}>
                <div className={styles.info}>
                    <BasicInfo data={data} />
                </div>
                <ReceiptImage order={data} />
            </div>
        </>
    );
};

const OrderPaidWithPix: FunctionComponent<WithData<PaidPixOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data} />
            <PaidInfo data={data} />
        </div>
        <ReceiptImage order={data} />
    </div>
);

const OrderPaidWithTed: FunctionComponent<WithData<PaidManuallyApprovedOrder>> = ({ data }) => (
    <div className={styles.content}>
        <div className={styles.info}>
            <BasicInfo data={data} />
            <PaidInfo data={data} />
        </div>
        <div className={styles.receipt}>
            <ReceiptImage order={data} />
            <Button label={t.translate('downloadReceipt')} onClick={() => {}} />
        </div>
    </div>
);

const BasicInfo: FunctionComponent<WithData<Order>> = ({ data }) => {
    return <>
        <Label text={t.translate('walletAddress')} content={data.address} />
        <Label text={t.translate('amount')} content={formatPriceAmount(data.amount)} />
        <Label text={t.translate('status')}
               content={t.translate(getOrderStatusKey(data.status))}
               className={getOrderStatusStyle(data)} />
        <Label text={t.translate('date')} content={formatDateWithTime(data.createdAt)} />
    </>;
};

const PaidInfo: FunctionComponent<WithData<PaidOrder>> = ({ data }) => (
    <>
        <Label text={t.translate('bank')} content={data.payment.bank} />
        <Label text={t.translate('paymentMethod')} content={getPaymentMethodLabel(data)} />
        <Label text={t.translate('externalId')} content={data.payment.externalId} />
        <Label text={t.translate('paymentDate')} content={formatDateWithTime(data.payment.statusDate)} />
    </>
);
