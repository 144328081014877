import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert, typeAlert } from '../types';
import { AppThunk, RootState } from './index';
import { generateUuid } from '../utils';

interface NotificationState {
    snacks: string[];
    alerts: Alert[];
}

const initialState: NotificationState = {
    snacks: [],
    alerts: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addSnack: (state, action: PayloadAction<string>) => {
            state.snacks = [...state.snacks, action.payload];
        },
        popSnack: state => {
            state.snacks = [...state.snacks.slice(1)];
        },
        addAlert: (state, action: PayloadAction<Alert>) => {
            state.alerts = [...state.alerts, action.payload];
        },
        hideAlert: (state, action: PayloadAction<string>) => {
            state.alerts = state.alerts.map(x => {
                if (x.id !== action.payload)
                    return x;

                x.show = false;
                return x;
            });
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            state.alerts = state.alerts.filter(x => x.id !== action.payload);
        },
        clearAlerts: state => {
            state.alerts = [];
        },
    },
});

const { addSnack, popSnack, addAlert, removeAlert, hideAlert } = notificationSlice.actions;
export const { clearAlerts } = notificationSlice.actions;

export const showSnack = (text: string): AppThunk => dispatch => {
    dispatch(addSnack(text));

    setTimeout(() => dispatch(popSnack()), 10000);
};

export const closeAlert = (alert: Alert): AppThunk => dispatch => {
    dispatch(hideAlert(alert.id));

    setTimeout(() => dispatch(removeAlert(alert.id)), 30000);
};

export const showAlert = (text: string, type: typeAlert = 'success'): AppThunk => dispatch => {
    const alert = { id: generateUuid(), text, type, show: true };
    dispatch(addAlert(alert));

    setTimeout(() => dispatch(closeAlert(alert)), 4000);
};


export const getLastSnack = (state: RootState): string | undefined => state.notificationState.snacks[0];
export const getAlerts = (state: RootState): Alert[] => state.notificationState.alerts;


export default notificationSlice.reducer;
