import { Order, OrderStatus, PaymentType } from '../types/Order';

export const getOrderHashId = (order: Order): string =>
    order.id.slice(-8);

export const getOrderStatus = (order: Order): string => {
    const dic: Record<OrderStatus, string> = {
        [OrderStatus.created]: 'Created',
        [OrderStatus.paid]: 'Paid',
        [OrderStatus.declined]: 'Not Approved',
        [OrderStatus.pending]: 'Pending Payment',
    };

    return dic[order.status];
};

export const getOrderStatusStyle = (order: Order): string => {
    return {
        [OrderStatus.paid]: 'success',
        [OrderStatus.declined]: 'error',
        [OrderStatus.pending]: '',
        [OrderStatus.created]: '',
    }[order.status] || '';
};

export const getPaymentMethodLabel = (order: Order): string => {
    if (order.status === OrderStatus.created)
        return 'Undefined';

    return {
        [PaymentType.ted]: 'Ted',
        [PaymentType.pix]: 'Pix',
    }[order.payment.type];
};
