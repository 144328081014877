import React from 'react';
import styles from './label.module.scss';

interface LabelProps {
    text: string;
    content: string;
    className?: string;
    children?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = (props) => {
    return (
        <div className={styles.label}>
            <span className={styles.title}>{props.text}</span>
            <div className={`${styles.content} ${props.className}`}>{props.content || props.children}</div>
        </div>
    );
};
