import React, { useCallback, useEffect } from 'react';
import styles from './edit-address.module.scss';
import { Field } from '../../utils/field';
import { Button, ButtonAppearance } from '../../utils/button';
import { t } from '../../../intl/Translator';
import { useAppDispatch } from '../../../hooks';
import { getAddress, isEditingAddress, setAddress, setIsEditingAddress } from '../../../store/appSlice';
import { useSelector } from 'react-redux';
import { Dialog } from '../../utils/dialog';

export const EditAddress: React.FC = () => {
    const dispatch = useAppDispatch();
    const previousAddress = useSelector(getAddress);

    const editingAddress = useSelector(isEditingAddress);

    const [newAddress, setNewAddress] = React.useState<string>('');

    useEffect(() => {
        setNewAddress(previousAddress);
    }, [previousAddress]);

    const isAValidTronNetwork = /T[A-Za-z1-9]{33}/.test(newAddress);
    const isAValidthereumNetwork = /^0x[a-fA-F0-9]{40}$/.test(newAddress);
    const isAddressValid = isAValidTronNetwork || isAValidthereumNetwork;

    const onSaveAddress = useCallback(() => {
        if (!isAddressValid) return;

        dispatch(setAddress(newAddress));
    }, [dispatch, isAddressValid, newAddress]);

    const onClose = useCallback(() => {
        dispatch(setIsEditingAddress(false));
    }, [dispatch]);

    return (
        <Dialog isOpen={editingAddress}
                title={t.translate('wallet')}
                onClose={!!previousAddress ? onClose : undefined}>
            <div className={styles.address}>
                <Field
                    label={t.translate('walletAddress')}
                    placeholder={'TADn2VCHwJ92Zpk8sdn8vYjUQQoX4RBUFZ'}
                    value={newAddress}
                    onChange={setNewAddress}
                    className={styles.field}
                    type={'text'}
                    required={true}
                />
                <div className={styles.action}>
                    <Button
                        label={t.translate('enter')}
                        appearance={ButtonAppearance.Outline}
                        onClick={onSaveAddress}
                        disabled={!isAddressValid}
                    />
                </div>
            </div>
        </Dialog>

    );
};
