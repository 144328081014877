import { Action, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Api } from '../services/Api';
import notificationState from './notificationSlice';
import { setUpPartner } from './authSlice';
import orderSlice, { setupOrderState } from './orderSlice';
import appSlice, { setUpAppState } from './appSlice';
import statementSlice from './statementSlice';

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
    reducer: {
        appSlice,
        notificationState,
        orderSlice,
        statementSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export type AppThunkDispatcher = ThunkDispatch<RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;
export const setupApplication = async (): Promise<void> => {
    Api.setDispatcher(store.dispatch);
    store.dispatch(setUpAppState());
    store.dispatch(setUpPartner());
    store.dispatch(setupOrderState())
};


export const getFpaidHost = () => {
    const location = window.location.href;
    const [protocol, dirtyHost] = location.split(':');
    const host = dirtyHost.replace(/\//g, '').replace(/partner\./, '');
    if(host === 'localhost')
        return 'http://localhost:4200';

    return `${protocol}://${host}`;
}