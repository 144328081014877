// import * as fs from 'fs';

import { translationDictionary } from './translationDictionary';

export enum Language {
    english = 1,
    portuguese,
    chinese
}

class Translator {
    private readonly language: Language;

    constructor() {
        this.language = {
            zh: Language.chinese,
            en: Language.english,
            pt: Language.portuguese,
        }[navigator.language.slice(0, 2)] || Language.english;
    }

    public translate = (key: string): string => {
        const term = translationDictionary.get(key);
        if (!term)
            return key;

        switch (this.language) {
            case Language.portuguese:
                return term.pt;
            case Language.chinese:
                return term.ch;
            default:
                return term.en;
        }
    };
}

export const t = new Translator();
