import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Charge, ChargeRequest } from '../types/Charge';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import OrderService from '../services/OrderService';
import { Order, OrderStatus } from '../types/Order';
import { delay, retrieveFromLocalStorage, saveOnLocalStorage, upsertOnList } from '../utils';
import { WebSocket } from '../services/WebSocket';

const ORDERS_KEY = 'orders';
const ORDERS_LAST_UPDATED_DATE_KEY = 'orders_last_updated';

interface OrderState {
    charge: Charge | null;
    lstOrders: Order[];
}

const initialState: OrderState = {
    charge: null,
    lstOrders: [],
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        updateCharge: (state, action: PayloadAction<Charge>) => {
            state.charge = action.payload;
        },
        closeCharge: state => {
            state.charge = null;
        },
        upsertOrder: (state, action: PayloadAction<Order>) => {
            state.lstOrders = upsertOnList(state.lstOrders, action.payload);
        },
    },
});

const { updateCharge, upsertOrder } = orderSlice.actions;
export const { closeCharge } = orderSlice.actions;

export const newCharge = (req: ChargeRequest): AppThunk => (dispatch, getState) => {
    OrderService.charge(req)
        .then((charge) => dispatch(updateCharge(charge)));
};

export const getCharge = (state: RootState): Charge | null => state.orderSlice.charge;
export const getIsChargeOpen = (state: RootState): boolean => !!state.orderSlice.charge;

export const getOrderFromCharge = (state: RootState): Order | undefined =>
    state.orderSlice.lstOrders.find(x => x.chargeId === state.orderSlice.charge?.id);

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Order[]>(ORDERS_KEY);

    if (!lst)
        return;

    lst.forEach(order => dispatch(upsertOrder(order)));
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const orders = getState().orderSlice.lstOrders;
    saveOnLocalStorage(ORDERS_KEY, orders);
};

export const setupOrderState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);
    WebSocket.onEvent<Order>('update-order', async order => {
        dispatch(upsertOrder(order));

        const lastUpdatedDate = new Date().toISOString();
        await saveOnLocalStorage(ORDERS_LAST_UPDATED_DATE_KEY, { lastUpdatedDate });

        return updateOnStorage(getState);
    });

    const { lastUpdatedDate } = retrieveFromLocalStorage<{ lastUpdatedDate: Date }>(ORDERS_LAST_UPDATED_DATE_KEY) || { lastUpdatedDate: new Date(0) };
    WebSocket.emitOnConnection('list-orders', { lastUpdatedDate });
};

const sortByDate = (item1: Order, item2: Order): 1 | -1 => item1.createdAt.getTime() > item2.createdAt.getTime() ? -1 : 1;

export const getOrdersTotal = (state: RootState): number => {
    const sumIfPaidOrPending = (total: number, order: Order) =>
        order.status === OrderStatus.paid || order.status === OrderStatus.pending ? total + order.amount : total;
    return state.orderSlice.lstOrders.reduce(sumIfPaidOrPending, 0);
};
export const getOrdersMapByDate = (state: RootState): Map<string, Order[]> => {
    const lst = state.orderSlice.lstOrders.filter(x => !!x && [OrderStatus.pending, OrderStatus.paid, OrderStatus.declined].includes(x.status));
    const orders = lst.sort(sortByDate);

    const dates = new Map<string, Order[]>();
    orders.forEach(order => {
        const dt = order.createdAt;
        const key = dt.toDateString();
        const oldValue: Order[] = dates.get(key) || [];
        dates.set(key, [...oldValue, order]);
    });
    return dates;
};

export const getOrder = (id: string) => (state: RootState): Order | undefined => state.orderSlice.lstOrders.find(x => x.id === id);
export default orderSlice.reducer;
