import { io as connect, Socket } from 'socket.io-client';
import { apiResponseFormatter } from '../utils';

const baseUrl = process.env.REACT_APP_WEBSOCKET_API;

type Handler<T> = (obj: T) => any;

interface EventSubscription {
    event: string;
    handler: Handler<any>;
}

interface OnConnectionEmitterPayload {
    event: string;
    payload: any;
}

export class WebSocket {
    private static connection: Socket;
    private static path = '/api/websocket/socket/';
    private static suffix = '/socket/general';
    private static subscriptions: EventSubscription[] = [];
    private static onConnectionEmitters: OnConnectionEmitterPayload[] = [];

    private static _token: string | null;

    private static get token(): string {
        return `Bearer ${this._token}`;
    }

    private static get url(): string {
        if (!this.token)
            throw new Error('WebSocket Missing token');

        return `${baseUrl}${this.suffix}`;
    }

    public static onEvent = <T>(event: string, handler: Handler<T>) => {
        this.subscriptions = [...this.subscriptions, { event, handler }];
    };

    public static emitOnConnection = <T>(event: string, payload: T) => {
        this.onConnectionEmitters = [...this.onConnectionEmitters, { event, payload }];

        if (!this.connection)
            return;

        this.connection.emit(event, payload);
    };

    public static updateToken = (token: string) => {
        this._token = token;

        if (!this.connection?.connected)
            return;

        if (!token) {
            this.connection.close();
            return;
        }

        this.connection.emit('update-token', this.token);
    };

    public static close = (): void => {
        if (!this.connection?.connected)
            return;

        this.connection.close();
    };

    public static openWebSocketConnection = async (): Promise<void> =>
        new Promise(async (resolve, reject) => {
            try {
                if (!this.token)
                    throw new Error('Please provide the token before creates the connection');

                if (this.connection?.connected) {
                    resolve();
                    return;
                }

                this.connection = connect(this.url, {
                    path: this.path,
                    reconnectionDelay: 10,
                    transports: ['websocket'],
                    query: {
                        authorization: this.token!,
                    },
                });

                this.connection.on('connect', () => {
                    console.log(`WS: Connection to ${this.url} established`);
                    resolve();

                    this.onConnectionEmitters.forEach(x => {
                        this.connection.emit(x.event, x.payload);
                    });

                    this.connection.onAny((event: string, value: any) => {
                        const handlers = this.subscriptions.filter(x => x.event === event);
                        const mappedObject = apiResponseFormatter(value);

                        handlers.forEach(x => x.handler(mappedObject));
                    });
                });

                this.connection.on('disconnect', () => {
                    console.log(`WS: Connection to websocket was closed`);
                });
            } catch (err) {
                console.error('Error on websocket');
                reject(err);
            }
        });
}
